<template>
  <div id="app" :class="{ 'p-0 bg-light': true, 'admin-login': showLoginElements }">
    <!-- <Loading /> -->
    <Header v-if="showLoginElements" />

    <template name="fade" v-if="showLoginElements">
      <div class="mt-0 row mr-0">
        <div
          class="col-12 col-md-2 pr-0"
          v-if="user && ((user.role === 'business' && user.status === 'approved') || user.role === 'business_user' || user.role === 'user' || user.role === 'sub_user')"
        >
          <SideMenu />
        </div>
        <div class="col">
          <router-view />
        </div>
      </div>
    </template>
    <template name="fade" mode="out-in" v-else>
      <router-view />
    </template>

    <Footer
      v-if="(user.role == 'business' && user.status == 'approved') || (user.role == 'business_user' && showLoginElements) || (user.role == 'user' && showLoginElements) || (user.role == 'sub_user' && showLoginElements)"
    />
  </div>
</template>

<script>
import SideMenu from "./components/Private/Shared/SideMenu";
import Header from "./components/Private/Shared/Header";
import Footer from "./components/Private/Shared/Footer";
import Echo from "laravel-echo";
import emitter from "./main";
// import Pusher from 'pusher-js';

window.Pusher = require("pusher-js");

export default {
  name: "App",
  data() {
    return {
      subscribed: false,
    };
  },
  components: {
    SideMenu,
    Header,
    Footer,
  },
  computed: {
    user() {
      return this.$store.getters["User/user"];
    },
    showLoginElements() {
      return this.user;
    },
    currentRouteName() {
      return this.$route;
    },
  },
  created() {
    this.subscribe();
  },
  watch: {
    user: function (val) {
      if (val && !this.subscribed) {
        this.subscribe();
      }
    },
  },
  methods: {
    subscribe() {
      if (this.user.token) {
        var userToken = this.user.token;
        this.subscribed = true;

        window.Echo = new Echo({
          broadcaster: "pusher",
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          host: process.env.VUE_APP_PUSHER_HOST,
          wsHost: process.env.VUE_APP_PUSHER_HOST,
          wsPort: process.env.VUE_APP_PUSHER_PORT,
          wssPort: process.env.VUE_APP_PUSHER_PORT,
          cluster: null,
          disableStats: false,
          encrypted: true,
          authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT + "broadcasting/auth",
          forceTLS: false,
          namespace: false,
          enabledTransports: ["ws", "wss"],
          auth: {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          },
        });

        window.UserChannel = window.Echo.private("MailExt." + this.user.id);

        window.UserOnlineChannel = window.Echo.private("MailExt." + "UserStatus");

        // window.PublicChannel = window.Echo.channel("MailExt." +  this.user.id);
        window.UserChannel.listen("App\\Events\\FavoriteConfirmation", (e) => {
          if (e) {
            emitter.emit("FavoriteConfirmation", e);
          }
        })
          .listen("App\\Events\\FavoriteDelete", (e) => {
            if (e) {
              emitter.emit("FavoriteDelete", e);
            }
          })
          .listen("App\\Events\\Confirmation", (e) => {
            if (e) {
              emitter.emit("Confirmation", e);
            }
          })
          .listen("App\\Events\\GlobalEmailDelete", (e) => {
            if (e) {
              emitter.emit("GlobalEmailDelete", e);
            }
          }).listen("App\\Events\\ReceivedFiles", (e) => {
            if (e) {
              // console.log(e);
              emitter.emit("ReceivedFiles", e);
            }
          }).listen("App\\Events\\NotRegisterUpdateSuperAdmin", (e) => {
            if (e) {
              emitter.emit("NotRegisterUpdateSuperAdmin", e);
            }
          });

          window.UserOnlineChannel.listen("App\\Events\\UserStatus",(e)=>{
            console.log(e);
            var user = e.user;
            // emitter.emit("UserStatus", user);
            this.$store.commit('User/SET_ACTIVE_USERS', user);
          })
      }
    },
  },
};
</script>
