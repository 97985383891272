<template>
  <header class="menu2 menu carm4_menu2 cid-sRt0z2bkZV bg-light pt-2 mr-3">
    <nav
      class="px-0 pb-0 pt-0 navbar navbar-light navbar-dropdown navbar-expand-sm navbar-fixed-top navbar-expand-lg"
    >
      <div class="navbar-brand ml-4">
        <span class="text-uppercase h6" v-if="user.role == 'admin'">Super admin</span>
        <span class="h6" v-else-if="user.role == 'user' || user.role == 'sub_user'">{{
          user.email
        }}</span>
        <span
          class="h6"
          v-else-if="
            (user.role == 'business' && user.status == 'approved') ||
            user.role == 'business_user'
          "
          >{{ user.company_name }}</span
        >
      </div>

      <!-- <div
        class="m-0 ml-auto row position-relative align-items-end"
        v-if="user.role == 'admin' || user.role == 'user'"
      > -->
      <div
        class="m-0 ml-auto row position-relative align-items-end"
        v-if="user.role == 'admin'"
      >
        <router-link
          :to="{ name: 'UserPackages' }"
          v-if="user.role == 'user'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none font-weight-bold': true,
            'active-menu': currentRouteName == 'UserPackages',
          }"
        >
          Prices
        </router-link>
        <router-link
          :to="{ name: 'GlobalList' }"
          v-if="user.role == 'admin'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none': true,
            'active-menu':
              currentRouteName == 'GlobalList' ||
              currentRouteName == 'GlobalEdit' ||
              currentRouteName == 'GlobalLinks' ||
              currentRouteName == 'CoutryCategory',
          }"
        >
          Global
        </router-link>
        <router-link
          :to="{ name: 'PackageAdd' }"
          v-if="user.role == 'admin'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none': true,
            'active-menu':
              currentRouteName == 'PackageAdd' ||
              currentRouteName == 'PackageEdit' ||
              currentRouteName == 'BusinessAdd' ||
              currentRouteName == 'BusinessEdit',
          }"
        >
          Packages
        </router-link>
        <router-link
          :to="{ name: 'BusinessPendingUsers', params: { status: 'pending' } }"
          v-if="user.role == 'admin'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none': true,
            'active-menu':
              currentRouteName == 'BusinessPendingUsers' ||
              currentRouteName == 'BusinessActiveUsers' ||
              currentRouteName == 'UserList' ||
              currentRouteName == 'BusinessUser' ||
              currentRouteName == 'BusinessUserLink' ||
              currentRouteName == 'BusinessAllUsersList' ||
              currentRouteName == 'UserEdit',
          }"
        >
          Users
        </router-link>
        <router-link
        :to="{ name: 'NotRegisterUserList', params: { type: 'private' } }"
          v-if="user.role == 'admin'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none': true,
            'active-menu': currentRouteName == 'NotRegisterUserList' || currentRouteName == 'AdminNotRegister',
          }"
        >
          Not Register Plan
        </router-link>
        <router-link
          :to="{ name: 'FavouriteList' }"
          v-if="user.role == 'user'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none font-weight-bold': true,
            'active-menu': currentRouteName == 'FavouriteList',
          }"
        >
          Favourite
        </router-link>
        <router-link
          :to="{ name: 'TemporaryEmail' }"
          v-if="user.role == 'user' && user.user_package && user.user_package.private_mode"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none font-weight-bold': true,
            'active-menu': currentRouteName == 'TemporaryEmail',
          }"
        >
          Private Mode
        </router-link>
        <router-link
          :to="{ name: 'NotRegister' }"
          v-if="user.role == 'user'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none font-weight-bold': true,
            'active-menu': currentRouteName == 'NotRegister',
          }"
        >
          Not Register
        </router-link>
        <!-- <router-link
          :to="{ name: 'ReceivedFiles' }"
          v-if="user.role == 'user'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none font-weight-bold': true,
            'active-menu': currentRouteName == 'ReceivedFiles',
          }"
        >
          Received Files
        </router-link> -->
        <router-link
          :to="{ name: 'ReceivedMail' }"
          v-if="user.role == 'user'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none font-weight-bold': true,
            'active-menu':
              currentRouteName == 'ReceivedMail' ||
              currentRouteName == 'ComposeMail' ||
              currentRouteName == 'SentMail',
          }"
        >
          Received Files
        </router-link>
        <router-link
          :to="{ name: 'Link' }"
          v-if="user.role == 'user'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none font-weight-bold': true,
            'active-menu': currentRouteName == 'Link',
          }"
        >
          Link
        </router-link>
        <router-link
          :to="{ name: 'BlackList' }"
          v-if="user.role == 'admin'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none': true,
            'active-menu': currentRouteName == 'BlackList',
          }"
        >
          Blacklisted
        </router-link>
        <router-link
          :to="{ name: 'SuspiciousMails' }"
          v-if="user.role == 'admin'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none': true,
            'active-menu': currentRouteName == 'SuspiciousMails' ||  currentRouteName ==  'SuspiciousMailEdit',
          }"
        >
          Suspicious E-Mails
        </router-link>
        <router-link
          :to="{ name: 'Configuration' }"
          v-if="user.role == 'admin'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none': true,
            'active-menu': currentRouteName == 'Configuration',
          }"
        >
          Configuration
        </router-link>
        <router-link
          :to="{ name: 'BlockWebsites' }"
          v-if="user.role == 'admin'"
          :class="{
            'text-dark mr-0 mb-0 pb-2 h6 ml-3 text-decoration-none': true,
            'active-menu': currentRouteName == 'BlockWebsites',
          }"
        >
          Block Websites
        </router-link>
        <a
          v-if="user.id"
          href="javascript::void();"
          :class="{
            'text-dark mr-0 h6 ml-3 text-decoration-none font-weight-bold': true,
          }"
          @click="logout"
          >Log Out</a
        >
      </div>

      <div
        class="m-0 ml-auto row"
        v-else-if="user.role == 'business' || user.role == 'business_user' || user.role == 'user' || user.role == 'sub_user'"
      >
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav nav-dropdown" data-app-modern-menu="true">
            <li class="nav-item mr-4">
              <span class="ml-3 mr-0 text-secondary h6 text-decoration-none">
                Welcome {{ user.name }}
              </span>
            </li>
          </ul>

          <div class="navbar-buttons mbr-section-btn">
            <ul class="navbar-nav">
              <li class="nav-item dropdown btn-group">
                <div
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-user-circle h2" aria-hidden="true"></i>
                </div>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a
                    v-if="user.id"
                    href="javascript::void();"
                    :class="{
                      'dropdown-item': true,
                    }"
                    @click="logout"
                  >
                    Log Out
                  </a>
                </div>
              </li>
            </ul>

            <!--             
            <a class="btn btn-sm btn-primary display-4" href="#"
              >Login / Sign in</a
            > -->
          </div>
        </div>
      </div>
    </nav>

    <div class="m-0 row">
      <div class="pl-0 col-md-2 col-sm-12">
        <router-link :to="{ name: '' }">
          <!-- <img :src="publicURL + 'images/blastchat.png'" alt="" class="login-logo w-75"> -->
        </router-link>
      </div>
    </div>
    <hr class="m-0 ml-3 mt-0" />
  </header>
</template>

<script>
import API from "../../../api/http";

export default {
  name: "Header",
  data() {
    return {
      publicURL: process.env.VUE_APP_BASE_URL,
    };
  },
  computed: {
    user() {
      return this.$store.getters["User/user"];
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    async logout() {
      var userRole = this.user.role;
      API.logout().then(async () => {
        if (userRole == "user") {
          // await this.$gAuth.signOut();
          this.$router.push({ name: "Login" });
        } else if (userRole == "business" || userRole == "business_user") {
          this.$router.push({ name: "Login" });
        } else {
          this.$router.push({ name: "AdminLogin" });
        }
      });
    },
  },
};
</script>
<style></style>
