<template>
  <footer class="bg-light text-dark pt-5 pb-4">
    <div class="container text-center text-md-left">
      <div class="row text-center text-md-left">
        <div class="col-md-4 col-lg-4 col-xl-4">
          <h5 class="mb-4 font-weight-bold text-dark">Explore</h5>
          <hr class="mb-4" />

          <p>
            <a href="" class="text-dark">Own product</a>
          </p>
          <p>
            <a href="" class="text-dark">Price</a>
          </p>
          <p>
            <a href="" class="text-dark">FAQ</a>
          </p>
          <p>
            <a href="" class="text-dark">About us</a>
          </p>
        </div>
        <div class="col-md-4 col-lg-4 col-xl-4">
          <h5 class="mb-4 font-weight-bold text-dark">Security</h5>
          <hr class="mb-4" />

          <p>
            <a href="" class="text-dark">One System</a>
          </p>
          <p>
            <a href="" class="text-dark">Layers</a>
          </p>
          <p>
            <a href="" class="text-dark">How it Works</a>
          </p>
          <p>
            <a href="" class="text-dark">Contact Us</a>
          </p>
        </div>
        <div class="col-md-4 col-lg-4 col-xl-4">
          <h5 class="mb-4 font-weight-bold text-dark">Connect</h5>
          <hr class="mb-4" />

          <a href="#" class="text-dark btn-floating btn-sm">
            <i class="fa fa-facebook" aria-hidden="true"> </i>
          </a>
          <a href="#" class="text-dark btn-floating btn-sm">
            <i class="fa fa-twitter" aria-hidden="true"> </i>
          </a>
          <a href="#" class="text-dark btn-floating btn-sm">
            <i class="fa fa-linkedin" aria-hidden="true"> </i>
          </a>
          <a href="#" class="text-dark btn-floating btn-sm">
            <i class="fa fa-youtube-play" aria-hidden="true"> </i>
          </a>
          <a href="#" class="text-dark btn-floating btn-sm">
            <i class="fa fa-rss" aria-hidden="true"> </i>
          </a>
        </div>
      </div>
      <hr class="mb-4" />

      <div class="media-container-row align-center mbr-white">
        <div class="col-md-12 text-center">
          <p>
            © Copyright {{ new Date().getFullYear() }} - All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>