import {
  createRouter,
  createWebHistory
} from "vue-router";
import publicRoutes from './public'
import privateRoutes from './private'
import store from '../store'

const routes = publicRoutes.concat(privateRoutes)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {

    store.dispatch('User/getAuth')
      .then((user) => {
        if (user) {
          if (user.role == 'business' || user.role == 'business_user') {
            if (store.getters['User/user_limits'].includes(to.name)) {
              next()
            } else {
              next({
                name: 'BusinessUsersDashboard'
              });
            }
          } else {
            next()
          }
        } else {
          next({
            name: 'Login'
          });
        }
      }).catch((error) => {
        store.commit('Message/SET_MESSAGE', {
          message: error.message,
          type: 'warning'
        })
        store.commit('User/SET_LOGIN_SUCCESS_ROUTE', to.name)
        next({
          name: 'Login'
        })
      })
  } else if (to.matched.some(route => route.meta.onlyGuest)) {
    try {
      store.dispatch('User/getAuth')
      .then(() => {
        // if (store.getters['User/isLoggedin']) {
        //   // next({name: 'Dashboard' })
        // } else {
          next()
        // }
      }).catch (()=> {
        next()
      })
    } catch (error) {
      next()
    }
    
  } else {
    if (store.getters['User/isFirstLoad']) {
      store.commit('User/SET_FIRST_LOAD_STATUS', false)
    }
    next()
  }
})

export default router