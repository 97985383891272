const publicRoutes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/admin-login',
    name: 'AdminLogin',
    component: () => import(/* webpackChunkName: "publicRoutes" */ '../components/Public/Login'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "publicRoutes" */ '../components/Public/UserLogin'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/business-list',
    name: 'BusinessPackages',
    component: () => import(/* webpackChunkName: "publicRoutes" */ '../components/Public/Businesses/List'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/business-register/:id',
    name: 'BusinessRegister',
    component: () => import(/* webpackChunkName: "publicRoutes" */ '../components/Public/Businesses/Register'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/success',
    name: 'RegisterSuccess',
    component: () => import(/* webpackChunkName: "publicRoutes" */ '../components/Public/Businesses/RegisterSuccess'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "publicRoutes" */ '../components/Public/ForgotPassword'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "publicRoutes" */ '../components/Public/ResetPassword'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/url',
    name: 'Url',
    component: () => import(/* webpackChunkName: "publicRoutes" */ '../components/Public/Url'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/extension-logout',
    name: 'ExtLogout',
    component: () => import(/* webpackChunkName: "publicRoutes" */ '../components/Public/ExtensionLogout'),
    meta: {
      onlyGuest: true
    }
  },
]

export default publicRoutes