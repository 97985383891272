import { createStore } from 'vuex'

import Storage from '@/helper/Storage'
import modules from './modules'

export default new createStore({
  modules,
  actions: {
    reset({ commit }) {
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`)
      })
      Storage.Clear()
    }
  },
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: 'b13c5a05-21bc-4641-9af0-6fb8bb10873b',
          authority:
            'https://login.microsoftonline.com/common/',
          redirectUri: 'http://localhost:8080/',
          scope: "profile openid email User.Read"
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      },
      accessToken: '',
    };
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    }
  }
});


