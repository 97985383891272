const privateRoutes = [{
    path: '',
    redirect: '/favourites',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/domains',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Domains/Domains'),
    name: 'Domains',
    meta: {
        requiresAuth: true,
    },
    children: [{
        path: '',
        name: 'DomainList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Domains/List'),
        meta: {
            requiresAuth: true,
        },
    },]
},
{
    path: '/country-category',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Globals/CoutryCategory'),
    name: 'CoutryCategory',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/favourites',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Favourites/Favourites'),
    name: 'Favourites',
    meta: {
        requiresAuth: true,
    },
    children: [{
        path: '',
        name: 'FavouriteList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Favourites/List'),
        meta: {
            requiresAuth: true,
        },
    },]
},
{
    path: '/blacklist',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/BlackList'),
    name: 'BlackList',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/suspicious-mails',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/SuspiciousMails'),
    name: 'SuspiciousMails',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/temporary-email',
    name: 'TemporaryEmail',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/TemporaryEmail'),
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/not-register',
    name: 'NotRegister',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/NotRegister'),
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/users',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/Users'),
    name: 'Users',
    meta: {
        requiresAuth: true,
    },
    children: [{
        path: '',
        name: 'UserList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/List'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'edit/:id',
        name: 'UserEdit',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/User'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'sent-mails/:id/:mail',
        name: 'SentMailList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/EmailList'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'sent-mail/:id',
        name: 'SentMail',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/SentMail'),
        meta: {
            requiresAuth: true,
        },
    },
    ]
},
{
    path: '/received-files',
    name: 'ReceivedFiles',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/ReceivedFiles'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/packages',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Packages/Packages'),
    name: 'Packages',
    meta: {
        requiresAuth: true,
    },
    children: [{
        path: '',
        name: 'PackageList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Packages/List'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'add',
        name: 'PackageAdd',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Packages/Package'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'edit/:id',
        name: 'PackageEdit',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Packages/Package'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'list',
        name: 'UserPackages',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Packages/PackageList'),
        meta: {
            requiresAuth: true,
        },
    }
    ]
},
{
    path: '/businesses',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Businesses/Businesses'),
    name: 'Businesses',
    meta: {
        requiresAuth: true,
    },
    children: [{
        path: '',
        name: 'BusinessList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Businesses/List'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'add',
        name: 'BusinessAdd',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Businesses/Business'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'edit/:id',
        name: 'BusinessEdit',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Businesses/Business'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'package-config/:id',
        name: 'PackageConfig',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Businesses/PackageConfig'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'register-link/:id',
        name: 'LinkRegister',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Businesses/LinkRegister'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'list',
        name: 'UserBusinesses',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Businesses/BusinessList'),
        meta: {
            requiresAuth: true,
        },
    }
    ]
},
{
    path: '/business-pending-users/:status',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/BusinessUsers'),
    name: 'BusinessPendingUsers',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/business-user',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Index'),
    name: 'BusinessUsers',
    meta: {
        requiresAuth: true,
    },
    children: [{
        path: 'packages',
        name: 'BusinessUsersPackages',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/BusinessPackages'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'dashboard',
        name: 'BusinessUsersDashboard',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Dashboard'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'glabal-emails',
        name: 'BusinessUsersGlobalEmails',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/GlobalEmails'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'favourites',
        name: 'BusinessUsersFavourites',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Favourites'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'account',
        name: 'BusinessUsersAccount',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/UserAccount'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'package',
        name: 'BusinessUsersPackage',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/UserPackage'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'list',
        name: 'BusinessUsersList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/UserList'),
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: 'add',
        name: 'BusinessUsersAdd',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Add'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'edit/:id',
        name: 'BusinessUsersEdit',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Add'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'domain-confirmation',
        name: 'BusinessUsersConfirmation',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Confirmation'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'favrite',
        name: 'ConfirmationFavorite',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/ConfirmationFavorite'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'blacklist',
        name: 'ConfirmBlackList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/ConfirmBlackList.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'global-links',
        name: 'BusinessUsersGlobalLinks',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Links/GlobalLinks'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'link-registration',
        name: 'BusinessUsersLinkRegistration',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Links/LinkRegistration'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'global-link-registration',
        name: 'BusinessUsersGlobalLinkRegistration',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Links/GlobalLinkRegistration'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'user-links',
        name: 'BusinessUsersUserLinks',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Links/UserLinks'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'employee-links/:id',
        name: 'BusinessUsersEmployeeLinks',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/Links/EmployeeLinks'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'senders/',
        name: 'BusinessUsersSenders',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/ReceivedFiles/Senders'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'sender-mails/:id',
        name: 'BusinessUsersSenderMails',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/ReceivedFiles/SenderMails'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'encryption',
        name: 'BusinessUsersAcceptEncryption',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/AcceptEncryption'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'not-register/:type',
        name: 'NotRegisterEmails',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/NotRegisterEmails'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'glabal-companies',
        name: 'GlobalCompanies',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/GlobalEmails'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'temporary-email',
        name: 'BusinessTemporaryEmail',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/TemporaryEmail'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'not-register-edit/:id',
        name: 'NotRegisterEdit',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/NotRegisterEdit'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'employee-packages',
        name: 'EmployeePackageList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/EmployeePackageList'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'add-employee-package',
        name: 'EmployeePackageAdd',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/AddEmployeePackage'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'employee-package/:id',
        name: 'EmployeePackageEdit',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/AddEmployeePackage'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'add-employee',
        name: 'AddEmployee',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/AddEmployee'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'edit-employee/:id',
        name: 'EditEmployee',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/AddEmployee'),
        meta: {
            requiresAuth: true,
        },
    },

    ]
},
{
    path: '/business-active-users/:status',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/BusinessUsers'),
    name: 'BusinessActiveUsers',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/business-user/:id',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/BusinessUser'),
    name: 'BusinessUser',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/business-user-link/:id',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/BusinessUserLink'),
    name: 'BusinessUserLink',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/globals',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Globals/Globals'),
    name: 'Globals',
    meta: {
        requiresAuth: true,
    },
    children: [{
        path: '',
        name: 'GlobalList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Globals/List'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'edit/:id',
        name: 'GlobalEdit',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Globals/Global'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'links',
        name: 'GlobalLinks',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Globals/GlobalLinks'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'name-protection',
        name: 'NameProtection',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Globals/NameProtection'),
        meta: {
            requiresAuth: true,
        },
    }
    ]
},
// {
//   path: '/users',
//   component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/List'),
//   name: 'UserList',
//   meta: {
//     requiresAuth: true,
//   },
// },
// {
//   path: '/users/edit/:id',
//   component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/User'),
//   name: 'UserEdit',
//   meta: {
//     requiresAuth: true,
//   },
// },
{
    path: '/configuration',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/Configuration'),
    name: 'Configuration',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/message-list',
    name: 'MessageList',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/MessageList'),
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/add-message',
    name: 'CreateTextMessage',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/MessageCreation'),
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/edit-message/:id',
    name: 'TextMessageEdit',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/MessageCreation'),
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/compose',
    name: 'ComposeMail',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Messages/Compose'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/received',
    name: 'ReceivedMail',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Messages/Received'),
    meta: {
        requiresAuth: true,
    },
},

// {
//     path:'/notreceivedmail',
//     name:'NotReceivedMail',
//     component:()=>import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Notreceived/NotReceivedEmail.vue'),
//     meta: {
//         requiresAuth: true,
//     },
// },
{
    path: '/received-user-wise-mails/:id',
    name: 'ReceivedUserMails',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Messages/ReceivedUserMails'),
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/sent-mail-user/:id',
    name: 'SentMailUserList',
    component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Messages/SentMailUserList'),
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/sent',
    name: 'SentMail',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Messages/Sent'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/link',
    name: 'Link',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/Link'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/business-user-list/:id',
    name: 'BusinessAllUsersList',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/BusinessUser/BusinessUsersList'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/block-websites',
    name: 'BlockWebsites',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/BlockWebsites'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/blacklist/:type',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/BusinessBlackList'),
    name: 'BusinessBlackList',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/business-global-emails/:id',
    name: 'BusinessGlobalEmails',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/BusinessGlobalEmails'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/business-favorite-emails/:id',
    name: 'BusinessFavoriteEmails',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/BusinessFavoriteEmails'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/protected-user-mail',
    name: 'ProtectedUserMail',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Messages/ProtectedUserMail'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/not-register/:type',
    name: 'NotRegisterUserList',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/NotRegisterUserList'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/not-register/:type/:id',
    name: 'AdminNotRegister',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/AdminNotRegister'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/suspicious-mail-edit/:id',
    name: 'SuspiciousMailEdit',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/SuspiciousMailEdit'),
    meta: {
        requiresAuth: true,
    },
},
{
    path: '/sub-user',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Globals/Globals'),
    name: 'SubUsers',
    meta: {
        requiresAuth: true,
    },
    children: [{
        path: 'list',
        name: 'SubUsersList',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/SubUsersList'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'add',
        name: 'AddSubUser',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/AddSubUser'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'edit/:id',
        name: 'SubUserEdit',
        component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/AddSubUser'),
        meta: {
            requiresAuth: true,
        },
    },
    ]
},
{
    path: '/private-blacklist/:type',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/PrivateBlackList'),
    name: 'PrivateBlackList',
    meta: {
        requiresAuth: true,
    }
},
{
    path: '/private-website/:type',
    component: () => import( /* webpackChunkName: "privateRoutes" */ '../components/Private/Users/PrivateWebsite'),
    name: 'PrivateWebsite',
    meta: {
        requiresAuth: true,
    }
},
]
export default privateRoutes