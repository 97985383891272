import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
// import gAuthPlugin from 'vue3-google-oauth2'
import "vue-toastification/dist/index.css";
import vue3GoogleLogin from 'vue3-google-login'
import mitt from 'mitt'


// Import Bootstrap
import 'bootstrap'


import './assets/scss/style.scss'

const emitter = mitt()
export default emitter

const app = createApp(App)
// app.use(gAuthPlugin, {
//     clientId: '231270038461-cv1rcos6ceqve72qhellbpmd7ljl3d70.apps.googleusercontent.com'
// })
app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  })
app.config.globalProperties.$msalInstance = {};
app.use(router)
app.use(store)
app.use(Toast)
app.mount('#app')