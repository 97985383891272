<template>
  <!-- MENU SIDEBAR-->
  <!-- Business -->
  <div
    v-if="user && (user.role == 'business' || user.role == 'business_user')"
    class="nav flex-column border-right sidebar bg-light fixed"
  >
    <div class="py-2 pl-4 mb-2">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>

    <router-link
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab border-bottom': currentRouteName == 'BusinessUsersDashboard',
      }"
      :to="{ name: 'BusinessUsersDashboard' }"
      ><i
        class="fa fa-home mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      Dashboard
    </router-link>

    <!-- <router-link
      v-if="userLimits.includes('BusinessUsersPackage')"
      :class="{
        'py-2 pl-2 mb-2': true,
        'active-tab': currentRouteName == 'BusinessUsersPackage',
      }"
      :to="{ name: 'BusinessUsersPackage' }"
      ><i class="fa fa-building mr-2" aria-hidden="true"></i>
      {{ user.user_business_package.package_name }}
    </router-link> -->

    <!-- <router-link
      v-if="userLimits.includes('BusinessUsersGlobalEmails')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'BusinessUsersGlobalEmails',
      }"
      :to="{ name: 'BusinessUsersGlobalEmails' }"
      ><i
        class="fa fa-envelope mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
    Emails <i class="ml-3 fa fa-angle-down" aria-hidden="true"></i>
    </router-link> -->

    <div v-if="userLimits.includes('BusinessUsersConfirmation')" class="py-2 pl-4 mb-1">
      <div
        data-toggle="collapse"
        data-target="#Emaillist"
        aria-controls="Emaillist"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        :class="{
          'font-weight-bold':
            currentRouteName == 'BusinessUsersGlobalEmails' ||
            currentRouteName == 'BusinessUsersFavourites',
        }"
      >
        <i
          class="fa fa-envelope mr-2"
          style="height: 20px; width: 20px"
          aria-hidden="true"
        ></i>

        Email &nbsp;
        <!-- {{ user.global_confirm_pending + user.fav_confirm_pending }} -->
        <i class="ml-3 fa fa-angle-down" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="Emaillist">
        <div class="card bg-light card-body border-0">
          <router-link
            v-if="userLimits.includes('BusinessUsersGlobalEmails')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'BusinessUsersGlobalEmails',
            }"
            :to="{ name: 'BusinessUsersGlobalEmails' }"
          >
            Global
          </router-link>

          <router-link
            v-if="userLimits.includes('BusinessUsersFavourites')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'BusinessUsersFavourites',
            }"
            :to="{ name: 'BusinessUsersFavourites' }"
          >
            Favorite
          </router-link>
        </div>
      </div>
    </div>

    <!-- <div v-if="userLimits.includes('BusinessUsersConfirmation')" class="py-2 pl-4 mb-1">
      <div
        data-toggle="collapse"
        data-target="#collapseExample"
        aria-controls="collapseExample"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        :class="{
          'font-weight-bold':
            currentRouteName == 'BusinessUsersConfirmation' ||
            currentRouteName == 'ConfirmationFavorite',
        }"
      >
        <i
          class="fa fa-check-circle-o mr-2"
          style="height: 20px; width: 20px"
          aria-hidden="true"
        ></i>

        Confirmation &nbsp;
        {{ user.global_confirm_pending + user.fav_confirm_pending }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="collapseExample">
        <div class="card bg-light card-body border-0">
          <router-link
            v-if="userLimits.includes('BusinessUsersConfirmation')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'BusinessUsersConfirmation',
            }"
            :to="{ name: 'BusinessUsersConfirmation' }"
          >
            Global &nbsp;&nbsp;&nbsp; {{ user.global_confirm_pending }}
          </router-link>

          <router-link
            v-if="userLimits.includes('BusinessUsersConfirmation')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'ConfirmationFavorite',
            }"
            :to="{ name: 'ConfirmationFavorite' }"
          >
            Favorite &nbsp;&nbsp; {{ user.fav_confirm_pending }}
          </router-link>

          <router-link
            v-if="userLimits.includes('BusinessUsersConfirmation')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'ConfirmBlackList',
            }"
            :to="{ name: 'ConfirmBlackList' }"
          >
            Black List &nbsp;&nbsp;
          </router-link>
        </div>
      </div>
    </div> -->

    <div class="py-2 pl-4 mb-1">
      <div
        data-toggle="collapse"
        data-target="#NotRecievedFile"
        aria-controls="NotRecievedFile"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        :class="{ 'font-weight-bold': currentRouteName == 'NotRegisterEmails' }"
      >
        <i
          class="far fa-envelope-open mr-2"
          style="height: 20px; width: 20px"
          aria-hidden="true"
        ></i>
        Not Registered
        <i class="fa fa-angle-down ml-2" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="NotRecievedFile">
        <div class="card bg-light card-body border-0">
          <router-link
            v-if="userLimits.includes('BusinessUsersConfirmation')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'BusinessUsersConfirmation',
            }"
            :to="{ name: 'BusinessUsersConfirmation' }"
          >
            Global &nbsp;&nbsp;&nbsp; {{ user.global_confirm_pending }}
          </router-link>
          <router-link
            v-if="userLimits.includes('NotRegisterEmails')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab':
                currentRouteName == 'NotRegisterEmails' &&
                this.$route.params.type == 'email',
            }"
            :to="{ name: 'NotRegisterEmails', params: { type: 'email' } }"
          >
            Email &nbsp;&nbsp;&nbsp; {{ user.not_register_pending }}
          </router-link>
          <router-link
            v-if="userLimits.includes('BusinessTemporaryEmail')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'BusinessTemporaryEmail',
            }"
            :to="{ name: 'BusinessTemporaryEmail' }"
          >
            Temporary Email
          </router-link>
        </div>
      </div>
    </div>

    <!-- <router-link
      v-if="userLimits.includes('BusinessUsersFavourites')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'BusinessUsersFavourites',
      }"
      :to="{ name: 'BusinessUsersFavourites' }"
      ><i
        class="fa fa-star mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      Favorite List
    </router-link> -->

    <router-link
      v-if="userLimits.includes('BusinessUsersAcceptEncryption')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'BusinessUsersAcceptEncryption',
      }"
      :to="{ name: 'BusinessUsersAcceptEncryption' }"
      ><i
        class="fa fa-lock mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      Accept Encryption
    </router-link>

    <!-- <router-link
      v-if="userLimits.includes('BusinessUsersList')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'BusinessUsersList',
      }"
      :to="{ name: 'BusinessUsersList' }"
    >
      <i
        class="fa fa-users mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      Users
    </router-link> -->

    <div class="py-2 pl-4 mb-1">
      <div
        data-toggle="collapse"
        data-target="#users"
        aria-controls="users"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
      <i
        class="fa fa-users mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>

        Users
        <i class="fa fa-angle-down ml-3" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="users">
        <div class="card bg-light card-body border-0">
          <router-link
            v-if="userLimits.includes('BusinessUsersList')"
            :class="{
              'py-2 pl-3 mb-1 ': true,
              'active-tab': currentRouteName == 'BusinessUsersList',
            }"
            :to="{ name: 'BusinessUsersList' }"
          >
            Employee
          </router-link>

          <router-link
            :to="{ name: 'EmployeePackageList' }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab': currentRouteName == 'EmployeePackageList',
            }"
          >
            Package
          </router-link>
        </div>
      </div>
    </div>

    <div class="py-2 pl-4 mb-1">
      <div
        data-toggle="collapse"
        data-target="#collapseExample1"
        aria-controls="collapseExample1"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i
          class="fa fa-link mr-2"
          style="height: 20px; width: 20px"
          aria-hidden="true"
        ></i>

        Links
        <i class="fa fa-angle-down ml-3" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="collapseExample1">
        <div class="card bg-light card-body border-0">
          <router-link
            v-if="userLimits.includes('BusinessUsersGlobalLinks')"
            :class="{
              'py-2 pl-3 mb-1 ': true,
              'active-tab': currentRouteName == 'BusinessUsersGlobalLinks',
            }"
            :to="{ name: 'BusinessUsersGlobalLinks' }"
          >
            Global Links
          </router-link>

          <router-link
            :to="{ name: 'BusinessUsersLinkRegistration' }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab': currentRouteName == 'BusinessUsersLinkRegistration',
            }"
          >
            Link Registration
          </router-link>

          <router-link
            v-if="user.role == 'business'"
            :to="{ name: 'BusinessUsersUserLinks' }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab': currentRouteName == 'BusinessUsersUserLinks',
            }"
          >
            User Link
          </router-link>
        </div>
      </div>
    </div>

    <div class="py-2 pl-4 mb-1">
      <div
        data-toggle="collapse"
        data-target="#messagelist"
        aria-controls="messagelist"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i
          class="fa fa-mobile mr-2"
          style="height: 20px; width: 20px"
          aria-hidden="true"
        ></i>

        Messages
        <i class="fa fa-angle-down ml-3" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="messagelist">
        <div class="card bg-light card-body border-0">
          <router-link
            :to="{ name: 'MessageList' }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab': currentRouteName == 'MessageList',
            }"
          >
            No Reply
          </router-link>
        </div>
      </div>
    </div>

    <!-- <router-link
      v-if="userLimits.includes('BusinessUsersGlobalLinks')"
      :class="{
        'py-2 pl-2 mb-2': true,
        'active-tab': currentRouteName == 'BusinessUsersGlobalLinks',
      }"
      :to="{ name: 'BusinessUsersGlobalLinks' }"
      ><i class="fa fa-link mr-2" aria-hidden="true"></i>
      Links
    </router-link> -->

    <!-- <router-link
      v-if="userLimits.includes('BlackList')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'BlackList',
      }"
      :to="{ name: 'BlackList' }"
      ><i class="fa fa-ban mr-2" style="height: 20px; width: 20px" aria-hidden="true"></i>
      Black List
    </router-link> -->

    <div class="py-2 pl-4 mb-1" v-if="userLimits.includes('BlackList')">
      <div
        data-toggle="collapse"
        data-target="#BlackListToggle"
        aria-controls="BlackListToggle"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        :class="{
          'font-weight-bold': currentRouteName == 'BusinessBlackList',
        }"
      >
        <i
          class="fa fa-ban mr-2"
          style="height: 20px; width: 20px"
          aria-hidden="true"
        ></i>

        Black List
        <i class="fa fa-angle-down ml-3" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="BlackListToggle">
        <div class="card bg-light card-body border-0">
          <router-link
            :class="{
              'py-2 pl-3 mb-1 ': true,
              'active-tab':
                currentRouteName == 'BusinessBlackList' && $route.params.type == 'email',
            }"
            :to="{ name: 'BusinessBlackList', params: { type: 'email' } }"
          >
            Email
          </router-link>

          <router-link
            :to="{ name: 'BusinessBlackList', params: { type: 'domain' } }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab':
                currentRouteName == 'BusinessBlackList' && $route.params.type == 'domain',
            }"
          >
            Domain
          </router-link>

          <router-link
            :to="{ name: 'BusinessBlackList', params: { type: 'ip' } }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab':
                currentRouteName == 'BusinessBlackList' && $route.params.type == 'ip',
            }"
          >
            IP Address
          </router-link>

          <router-link
            :to="{ name: 'BusinessBlackList', params: { type: 'block-email' } }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab':
                currentRouteName == 'BusinessBlackList' &&
                $route.params.type == 'block-email',
            }"
          >
            Block Email
          </router-link>

          <router-link
            :to="{ name: 'BusinessBlackList', params: { type: 'link' } }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab':
                currentRouteName == 'BusinessBlackList' && $route.params.type == 'link',
            }"
          >
            Links
          </router-link>
          <router-link
            :to="{ name: 'BusinessBlackList', params: { type: 'sender_name' } }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab':
                currentRouteName == 'BusinessBlackList' &&
                $route.params.type == 'sender_name',
            }"
          >
            Sender Name
          </router-link>
          <router-link
            :to="{ name: 'BusinessBlackList', params: { type: 'received_from' } }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab':
                currentRouteName == 'BusinessBlackList' &&
                $route.params.type == 'received_from',
            }"
          >
            Received From
          </router-link>
          <router-link
            :to="{ name: 'BusinessBlackList', params: { type: 'country_domain' } }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab':
                currentRouteName == 'BusinessBlackList' &&
                $route.params.type == 'country_domain',
            }"
          >
            Country Domain
          </router-link>
          <router-link
            :to="{ name: 'BusinessBlackList', params: { type: 'website' } }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab':
                currentRouteName == 'BusinessBlackList' &&
                $route.params.type == 'website',
            }"
          >
            Website
          </router-link>
        </div>
      </div>
    </div>

    <div class="py-2 pl-4 mb-1">
      <div
        data-toggle="collapse"
        data-target="#Messages"
        aria-controls="Messages"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        :class="{ 'font-weight-bold': currentRouteName == 'BusinessUsersConfirmation' }"
      >
        <i
          class="fa fa-file mr-2"
          style="height: 20px; width: 20px"
          aria-hidden="true"
        ></i>

        Files <i class="fa fa-angle-down ml-4" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="Messages">
        <div class="card bg-light card-body border-0">
          <router-link
            v-if="userLimits.includes('ComposeMail')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'ComposeMail',
            }"
            :to="{ name: 'ComposeMail' }"
          >
            Compose
          </router-link>

          <router-link
            v-if="userLimits.includes('ReceivedMail')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab':
                currentRouteName == 'ReceivedMail' ||
                currentRouteName == 'ReceivedUserMails',
            }"
            :to="{ name: 'ReceivedMail' }"
          >
            Receive
          </router-link>

          <router-link
            v-if="userLimits.includes('SentMail')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab':
                currentRouteName == 'SentMail' || currentRouteName == 'SentMailUserList',
            }"
            :to="{ name: 'SentMail' }"
          >
            Sent
          </router-link>
          <router-link
            v-if="userLimits.includes('ProtectedUserMail')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'ProtectedUserMail',
            }"
            :to="{ name: 'ProtectedUserMail' }"
          >
            From Not Register
          </router-link>
        </div>
      </div>
    </div>

    <!-- 
    <router-link
      v-if="userLimits.includes('BusinessUsersSenders')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'BusinessUsersSenders',
      }"
      :to="{ name: 'BusinessUsersSenders' }"
    >
      <i class="fa fa-file mr-2" style="height: 20px; width: 20px" aria-hidden="true"></i>
      Received Files
    </router-link>

    <div class="py-2 pl-4 mb-1">
      <div
        data-toggle="collapse"
        data-target="#messagelist"
        aria-controls="messagelist"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i
          class="fa fa-file mr-2"
          style="height: 20px; width: 20px"
          aria-hidden="true"
        ></i>

        Received Files
        <i class="fa fa-angle-down ml-3" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="messagelist">
        <div class="card bg-light card-body border-0">

          <router-link
            :to="{ name: 'MessageList' }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab': currentRouteName == 'MessageList',
            }"
          >
            Compose
          </router-link>
        

          <router-link
            :to="{ name: 'MessageList' }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab': currentRouteName == 'MessageList',
            }"
          >
            Receive
          </router-link>
       

          <router-link
            :to="{ name: 'MessageList' }"
            :class="{
              'py-2 pl-3 mb-1': true,
              'active-tab': currentRouteName == 'MessageList',
            }"
          >
            Sent
          </router-link>
        </div>
      </div>
    </div> -->

    <!-- <div class="py-2 pl-4 mb-1">
      <div
        data-toggle="collapse"
        data-target="#NotRecievedFile"
        aria-controls="NotRecievedFile"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        :class="{ 'font-weight-bold': currentRouteName == 'NotRegisterEmails' }"
      >
      <i
        class="far fa-envelope-open mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
    Not Registered<i class="fa fa-angle-down  ml-2" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="NotRecievedFile">
        <div class="card bg-light card-body border-0">
          <router-link
            v-if="userLimits.includes('NotRegisterEmails')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'NotRegisterEmails' && this.$route.params.type == 'email',
            }"
            :to="{ name: 'NotRegisterEmails',params:{type:'email'} }"
          >
            Email
          </router-link>

          <router-link
            v-if="userLimits.includes('NotRegisterEmails')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'NotRegisterEmails' && this.$route.params.type == 'suspicious_email',
            }"
            :to="{ name: 'NotRegisterEmails' ,  params: { type:'suspicious_email' } }"
          >
            Suspicious Email
          </router-link>

          <router-link
            v-if="userLimits.includes('NotRegisterEmails')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'NotRegisterEmails' && this.$route.params.type == 'spam',
            }"
            :to="{ name: 'NotRegisterEmails', params: { type:'spam' } }"
          >
            Spam
          </router-link>
        </div>
      </div>
    </div> -->

    <!-- <router-link
      v-if="userLimits.includes('NotRegisterEmails')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'NotRegisterEmails',
      }"
      :to="{ name: 'NotRegisterEmails' }"
    >
     
    </router-link> -->

    <router-link
      v-if="userLimits.includes('BusinessUsersPackages')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'BusinessUsersPackages',
      }"
      :to="{ name: 'BusinessUsersPackages' }"
    >
      <i
        class="fa fa-archive mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      All Package
    </router-link>

    <router-link
      v-if="userLimits.includes('BusinessUsersAccount')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'BusinessUsersAccount',
      }"
      :to="{ name: 'BusinessUsersAccount' }"
    >
      <i class="fa fa-user-circle-o mr-2" style="height: 20px; width: 20px"></i>
      My Account
    </router-link>
  </div>

  <!-- Private  -->
  <div
    v-else-if="user && (user.role == 'user' || user.role == 'sub_user')"
    class="nav flex-column border-right sidebar bg-light fixed"
  >
    <div class="py-2 pl-4 mb-1">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>

    <router-link
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab border-bottom': currentRouteName == 'BusinessUsersDashboard',
      }"
      :to="{ name: 'BusinessUsersDashboard' }"
      ><i
        class="fa fa-home mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      Dashboard
    </router-link>

    <router-link
      v-if="userLimits.includes('FavouriteList')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'FavouriteList',
      }"
      :to="{ name: 'FavouriteList' }"
    >
      <i
        class="fa fa-envelope mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      Favorite
    </router-link>

    <router-link
      v-if="userLimits.includes('NotRegister')"
      :to="{ name: 'NotRegister' }"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'NotRegister',
      }"
    >
      <i
        class="far fa-envelope-open mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      Not Register
    </router-link>

    <router-link
      v-if="userLimits.includes('TemporaryEmail')"
      :to="{ name: 'TemporaryEmail' }"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'TemporaryEmail',
      }"
    >
      <i
        class="fa fa-minus-circle mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      Private Mode
    </router-link>

    <div class="py-2 pl-4 mb-1">
      <div
        data-toggle="collapse"
        data-target="#Messages"
        aria-controls="Messages"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        :class="{
          'font-weight-bold':
            currentRouteName == 'ReceivedMail' ||
            currentRouteName == 'ComposeMail' ||
            currentRouteName == 'SentMail',
        }"
      >
        <i
          class="fa fa-file mr-2"
          style="height: 20px; width: 20px"
          aria-hidden="true"
        ></i>

        Files <i class="fa fa-angle-down ml-4" aria-hidden="true"></i>
      </div>
      <div class="collapse" id="Messages">
        <div class="card bg-light card-body border-0">
          <router-link
            v-if="userLimits.includes('ComposeMail')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'ComposeMail',
            }"
            :to="{ name: 'ComposeMail' }"
          >
            Compose
          </router-link>

          <router-link
            v-if="userLimits.includes('ReceivedMail')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'ReceivedMail',
            }"
            :to="{ name: 'ReceivedMail' }"
          >
            Receive
          </router-link>

          <router-link
            v-if="userLimits.includes('SentMail')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'SentMail',
            }"
            :to="{ name: 'SentMail' }"
          >
            Sent
          </router-link>

          <router-link
            v-if="userLimits.includes('ProtectedUserMail')"
            :class="{
              'py-2 pl-4 mb-1': true,
              'active-tab': currentRouteName == 'ProtectedUserMail',
            }"
            :to="{ name: 'ProtectedUserMail' }"
          >
            From Not Register
          </router-link>
        </div>
      </div>
    </div>

    <router-link
      v-if="userLimits.includes('Link')"
      :to="{ name: 'Link' }"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'Link',
      }"
    >
      <i class="fa fa-link mr-2" style="height: 20px; width: 20px" aria-hidden="true"></i>
      Link
    </router-link>

    <router-link
      v-if="userLimits.includes('SubUsersList')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'SubUsersList',
      }"
      :to="{ name: 'SubUsersList' }"
    >
      <i
        class="fa fa-users mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      Users
    </router-link>
    <router-link
      v-if="userLimits.includes('PrivateBlackList')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'PrivateBlackList',
      }"
      :to="{ name: 'PrivateBlackList', params: { type: 'email' } }"
    >
      <i class="fa fa-ban mr-2" style="height: 20px; width: 20px" aria-hidden="true"></i>
      Blacklist
    </router-link>
    <router-link
      v-if="userLimits.includes('PrivateWebsite')"
      :class="{
        'py-2 pl-4 mb-1 text-decoration-none': true,
        'active-tab': currentRouteName == 'PrivateWebsite',
      }"
      :to="{ name: 'PrivateWebsite', params: { type: 'block_all_domain' } }"
    >
      <span class="mr-2 pr-1 font-weight-bold">W</span>
      Website
    </router-link>
    <router-link
      v-if="userLimits.includes('BusinessUsersPackages')"
      :class="{
        'py-2 pl-4 mb-1': true,
        'active-tab': currentRouteName == 'BusinessUsersPackages',
      }"
      :to="{ name: 'BusinessUsersPackages' }"
    >
      <i
        class="fa fa-archive mr-2"
        style="height: 20px; width: 20px"
        aria-hidden="true"
      ></i>
      All Package
    </router-link>
  </div>

  <!-- END MENU SIDEBAR-->
</template>

<script>
export default {
  name: "SideMenu",
  computed: {
    user() {
      return this.$store.getters["User/user"];
    },
    userLimits() {
      return this.$store.getters["User/user_limits"];
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
