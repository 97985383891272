import API from './api'
import store from '../store'

export default {

  authenticate(data) {
    return new Promise((resolve) => {
      var url = '';
      if (data.userLogin) {
        url = 'user-login';
      } else {
        url = 'login';
      }
      API.post(url, data).then((response) => {
        if (response && response.data.user) {
          response.data.user['token'] = response.data.token
          this.setUser(response.data.user);
          resolve(response.data.user)
        }
        resolve(false)
      }).catch((error) => {
        error;
      })
    })
  },
  logout(data) {
    return new Promise((resolve) => {
      API.post('logout', data).then(() => {
        store.dispatch('reset')
        resolve()
      }).catch(error => {
        store.commit('Message/SET_MESSAGE', {
          message: error.response.data.errors.join('<br>'),
          type: 'warning'
        })
      })
    })
  },
  setUser(user) {
    if (user) {
      if (!user.token) {
        user.token = store.getters['User/user'].token
      }
      if (user.role == 'business' || user.role == 'business_user' || user.role == 'user' || user.role == 'sub_user') {
        var userAccesses = []
        switch (user.role) {
          case 'business':
            userAccesses = [
              'PackageConfig',
              'BusinessUsersPackages',
              'BusinessUsersDashboard',
              'BusinessUsersGlobalEmails',
              'BusinessUsersFavourites',
              'BusinessUsersAccount',
              'BusinessUsersPackage',
              'BusinessUsersConfirmation',
              'ConfirmationFavorite',
              'LinkRegister',
              'BusinessUserMessageList',
              // 'ComposeMail',
              'ReceivedMail',
              'SentMail',
              // 'MessageCreation',
              'MessageList',
              'CreateTextMessage',
              'TextMessageEdit',
              'ReceivedUserMails',
              'SentMailUserList',
              'NotRegisterEmails',
              'ConfirmBlackList',
              'GlobalCompanies',
              'NotRegisterEdit',
              'EmployeePackageList',
              'EmployeePackageEdit',
              'EmployeePackageAdd',
              'AddEmployee',
              'EditEmployee'
            ];
            if (user.user_business_package.bl_email || user.user_business_package.bl_domain || user.user_business_package.bl_ip) {
              userAccesses.push('BlackList')
              userAccesses.push('BusinessBlackList')
            }

            if (user.user_business_package.add_users) {
              userAccesses.push('BusinessUsersList')
              userAccesses.push('BusinessUsersAdd')
              userAccesses.push('BusinessUsersEdit')
            }

            if (user.user_business_package.encryption_box) {
              userAccesses.push('BusinessUsersSenders')
              userAccesses.push('BusinessUsersSenderMails')
              userAccesses.push('BusinessUsersAcceptEncryption')
            }

            // if (user.user_business_package.access_global_link) {

            userAccesses.push('BusinessUsersGlobalLinks')
            userAccesses.push('BusinessUsersLinkRegistration')
            userAccesses.push('BusinessUsersEmployeeLinks')
            userAccesses.push('BusinessUsersUserLinks')
            // }
            if (user.user_business_package.add_global_link) {
              userAccesses.push('BusinessUsersGlobalLinkRegistration')
            }
            if (user.user_business_package.send_msg_and_files) {
              userAccesses.push('ComposeMail')
            }
            if (user.user_business_package.private_mode) {
              userAccesses.push('ProtectedUserMail')
              userAccesses.push('BusinessTemporaryEmail')
            }
            store.commit('User/SET_ACCOUNT_LIMITS', userAccesses)
            break;

          case 'business_user':
            userAccesses = [
              'BusinessUsersDashboard',
              'BusinessUsersGlobalEmails',
              'ConfirmationFavorite',
              'ReceivedMail',
              'SentMail',
              'ReceivedUserMails',
              'SentMailUserList',
              'GlobalCompanies',
              'NotRegisterEdit',
              'EmployeePackageList',
              'EmployeePackageEdit',
              'EmployeePackageAdd',
              'AddEmployee',
              'EditEmployee'
            ];
            if (user.bl_email || user.bl_domain || user.bl_ip) {
              userAccesses.push('BlackList')
              userAccesses.push('BusinessBlackList')
            }

            if (user.access_confirmation) {
              userAccesses.push('BusinessUsersConfirmation')
            }

            if (user.add_users) {
              userAccesses.push('BusinessUsersList')
              userAccesses.push('BusinessUsersAdd')
              userAccesses.push('BusinessUsersEdit')
            }

            if (user.confirm_link) {
              userAccesses.push('BusinessUsersLinkRegistration')
              userAccesses.push('BusinessUsersGlobalLinks')
            }

            if (user.add_global_link) {
              userAccesses.push('BusinessUsersGlobalLinkRegistration')
            }

            if (user.user_business_package.encryption_box) {
              userAccesses.push('BusinessUsersSenders')
              userAccesses.push('BusinessUsersSenderMails')
            }

            if (user.access_company_favourite) {
              userAccesses.push('BusinessUsersFavourites')
            }
            if (user.user_business_package.send_msg_and_files) {
              userAccesses.push('ComposeMail')
            }
            if (user.access_not_register_folder) {
              userAccesses.push('NotRegisterEmails')
              userAccesses.push('NotRegisterEdit')
            }
            if (user.user_business_package.private_mode) {
              userAccesses.push('ProtectedUserMail')
              userAccesses.push('BusinessTemporaryEmail')
            }
            store.commit('User/SET_ACCOUNT_LIMITS', userAccesses)
            break;

          case 'user':
            userAccesses = [
              'BusinessUsersDashboard',
              'ReceivedMail',
              'SentMail',
              'SubUsersList',
              'FavouriteList',
              'NotRegister',
              'NotRegisterEdit',
              'ProtectedUserMail',
              'Link',
              'PrivateWebsite',
              'BusinessUsersPackages',
              'PrivateBlackList'
            ];
            if (user.user_package.add_users) {
              userAccesses.push('SubUsersList')
              userAccesses.push('AddSubUser')
              userAccesses.push('SubUserEdit')
            }
            if (user.user_package.private_mode) {
              userAccesses.push('ProtectedUserMail')
              userAccesses.push('TemporaryEmail')
            }
            if (user.user_package.send_msg_and_files) {
              userAccesses.push('ComposeMail')
            }
            store.commit('User/SET_ACCOUNT_LIMITS', userAccesses)
            break;

          case 'sub_user':
            userAccesses = [
              'BusinessUsersDashboard',
              'ReceivedMail',
              'SentMail',
              'ProtectedUserMail',
            ];
            if (user.add_users) {
              userAccesses.push('SubUsersList')
              userAccesses.push('AddSubUser')
              userAccesses.push('SubUserEdit')
            }
            if (user.access_admin_panel_private_mode) {
              userAccesses.push('ProtectedUserMail')
              userAccesses.push('TemporaryEmail')
            }
            if (user.access_company_favourite) {
              userAccesses.push('FavouriteList')
            }
            if (user.access_not_register_folder) {
              userAccesses.push('NotRegister')
              userAccesses.push('NotRegisterEdit')
            }
            if (user.user_package.send_msg_and_files && user.access_received_files) {
              userAccesses.push('ComposeMail')
            }
            if (user.add_global_link) {
              userAccesses.push('Link')
            }
            if (user.access_admin_panel_blacklist) {
              userAccesses.push('PrivateBlackList')
            }
            if (user.website) {
              userAccesses.push('PrivateWebsite')
            }
            if (user.access_all_packages) {
              userAccesses.push('BusinessUsersPackages')
            }
            store.commit('User/SET_ACCOUNT_LIMITS', userAccesses)
            break;

          default:
            break;
        }
      }
      store.commit('User/SET_LOGIN_STATUS', true)
    } else {
      store.commit('User/SET_LOGIN_STATUS', false)
    }
    store.commit('User/SET_USER', user)
  },
  validateToken() {
    return new Promise((resolve) => {
      // TO DO reject
      resolve()
    })
  },
  get(url) {
    return new Promise((resolve, reject) => {
      API.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        store.commit('Message/SET_MESSAGE', {
          message: error.response.data.errors.join('<br>'),
          type: 'warning'
        })
        reject(error)
      })
    })
  },
  post(url, data, header = {}) {
    return new Promise((resolve, reject) => {

      API.post(url, data, {
        headers: header
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        // store.commit('Message/SET_MESSAGE', {
        //   message: error.response.data.errors.join('<br>'),
        //   type: 'warning'
        // })
        reject(error)
      })
    })
  }
}